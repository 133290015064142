import { useState, useEffect } from "react";
import {
  Image,
  Badge,
  Flex,
  useBreakpointValue,
  Box,
  useColorModeValue,
  IconButton,
  Text,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { IconContext } from "react-icons";
import { BsStarFill, BsStarHalf, BsStar } from "react-icons/bs";
import { doc, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import { db } from "../../services/firebase";
import { useMeta } from "../../contexts/MetaContext";
import { useAuth } from "../../contexts/AuthContext";
import SignUpModal from "../Modals/SignupModal";

function Rating({ rating }) {
  return (
    <Box d="flex" alignItems="center">
      {Array(5)
        .fill("")
        .map((_, i) => {
          const roundedRating = Math.round(rating * 2) / 2;
          if (roundedRating - i >= 1) {
            return (
              <BsStarFill
                key={i}
                style={{ marginLeft: "1" }}
                color={i < rating ? "teal.500" : "gray.300"}
              />
            );
          }
          if (roundedRating - i === 0.5) {
            return <BsStarHalf key={i} style={{ marginLeft: "1" }} />;
          }
          return <BsStar key={i} style={{ marginLeft: "1" }} />;
        })}
    </Box>
  );
}

const SingleProduct = ({ product }) => {
  const isDark = useColorModeValue(false, true);
  const isMobile = useBreakpointValue({ base: false, md: true });
  const { wishlist } = useMeta();
  const { currentUser } = useAuth();
  const [isWishlisted, setIsWishlisted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    if (typeof wishlist === "object") {
      for (let i = 0; i < wishlist.length; i++) {
        if (wishlist[i].name === product.name) {
          return setIsWishlisted(true);
        }
      }
    } else {
      setShowModal(true);
    }
  }, [product, wishlist]);
  const addToWishList = () => {
    if (typeof currentUser !== "string") {
      const docRef = doc(db, "users", currentUser.uid);
      updateDoc(docRef, {
        wishlist: arrayUnion(product),
      })
        .then(() => {
          setIsWishlisted(true);
        })
        .catch((err) => console.log(err));
    } else {
      setShowModal(true);
    }
  };
  const removeFromWishList = () => {
    if (typeof currentUser !== "string") {
      const docRef = doc(db, "users", currentUser.uid);
      updateDoc(docRef, {
        wishlist: arrayRemove(product),
      })
        .then(() => {
          setIsWishlisted(false);
        })
        .catch((err) => console.log(err));
    }
  };
  return (
    <Box
      bg={isDark ? "gray.800" : "white"}
      w={isMobile ? "350px" : "full"}
      height="auto"
      borderWidth="1px"
      rounded="lg"
      shadow="lg"
      position="relative"
      mx="6"
      mt="10"
    >
      <Link to={`/products/example`}>
        <Image
          src={product.imageURL}
          bgColor="white"
          alt={product.name}
          roundedTop="lg"
          h={isMobile ? "325px" : "full"}
          objectFit="cover"
          mr={"auto"}
          ml="auto"
          w="100%"
        />
      </Link>
      <Box p="6">
        <Link to={`/products/example`}>
          <Box d="flex" alignItems="baseline">
            {product.isNew ? (
              <Badge rounded="full" px="2" fontSize="0.8em" colorScheme="red">
                New
              </Badge>
            ) : (
              <Box rounded="full" px="2" fontSize="0.8em">
                &nbsp;
              </Box>
            )}
          </Box>
        </Link>
        <Flex mt="1" justifyContent="space-between" alignContent="center">
          <Link to={`/products/example`}>
            <Flex flexDir="column">
              <Box
                fontSize="2xl"
                fontWeight="semibold"
                as="h4"
                lineHeight="tight"
                isTruncated
              >
                {product.name}
              </Box>
              <Text py="1" fontWeight="medium">
                {product.category}
              </Text>
            </Flex>
          </Link>
          {isWishlisted ? (
            <IconContext.Provider value={{ size: "1.5em" }}>
              <IconButton
                icon={<AiFillHeart />}
                variant="ghost"
                size="lg"
                onClick={() => removeFromWishList()}
              />
            </IconContext.Provider>
          ) : (
            <IconContext.Provider value={{ size: "1.5em" }}>
              <IconButton
                icon={<AiOutlineHeart />}
                size="lg"
                variant="ghost"
                onClick={() => addToWishList()}
              />
            </IconContext.Provider>
          )}
        </Flex>

        <Flex justifyContent="space-between" alignContent="center">
          <Rating rating={product.rating} />
        </Flex>
      </Box>
      {typeof currentUser === "string" && (
        <SignUpModal isOpen={showModal} onClose={setShowModal} />
      )}
    </Box>
  );
};

export default SingleProduct;
