import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDA7QROTP06i_ipl-sVKmDl6ICjiIyoNdw",
  authDomain: "jay2646.firebaseapp.com",
  projectId: "jay2646",
  storageBucket: "jay2646.appspot.com",
  messagingSenderId: "1058792494337",
  appId: "1:1058792494337:web:36546fcaa8c6ee009cf700",
  measurementId: "G-TN78KQB2PW",
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

const db = getFirestore(app);

const analytics = getAnalytics(app);

export { auth, db, analytics };
