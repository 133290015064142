import React from "react";
import { Flex, Box, Image } from "@chakra-ui/react";
import { BsChevronDown } from "react-icons/bs";
import { IconContext } from "react-icons";
import { Link } from "react-scroll";
import { motion } from "framer-motion";
import SingleLogo from "../assets/only_logo.png";

const HomeSlide = ({ url, showDown }) => {
  return (
    <Box w="100%" maxH="100vh" overflow="hidden">
      <Image
        filter="brightness(50%)"
        minH="100vh"
        objectFit="cover"
        w="100%"
        src={url}
        alt="Home"
      />
      <Flex
        flexDir="column"
        pos="absolute"
        left="50%"
        top="calc(100vh - 50vh)"
        zIndex="2"
        transform="translate(-50%,-50%)"
        textAlign="center"
      >
        <Image src={SingleLogo} height="200px" alt="Logo" />
      </Flex>
      {showDown && (
        <Flex
          position="absolute"
          top="calc(100vh - 15vh)"
          left="50%"
          transform="translateX(-50%)"
          zIndex="4"
          _hover={{
            cursor: "pointer",
          }}
        >
          <motion.div
            animate={{
              y: [0, 20, 0, 20, 0, 20, 0, 20, 0, 20, 0],
            }}
            transition={{
              duration: 5,
            }}
          >
            <IconContext.Provider value={{ color: "#fff", size: "3em" }}>
              <Link
                to="stickyNavbar"
                smooth="true"
                href="http://this-page-intentionally-left-blank.org/"
              >
                <BsChevronDown />
              </Link>
            </IconContext.Provider>
          </motion.div>
        </Flex>
      )}
    </Box>
  );
};

export default HomeSlide;
