import { useRef } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import Signup from "../Forms/SignupForm";
import { useAuth } from "../../contexts/AuthContext";
import { Flex, Text } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";

export default function Email({ isOpen, onClose }) {
  const initialRef = useRef();
  const { currentUser } = useAuth();

  return (
    <>
      <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={onClose}
        size="lg"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {typeof currentUser === "string"
              ? "Create Your Account"
              : "Get the Brochure!"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {typeof currentUser !== "string" ? (
              <Flex flexDir="column" align="center" justify="center">
                <Text>
                  We will send you the brochure to this{" "}
                  <Text as="span" fontWeight="extrabold">
                    {currentUser.email}
                  </Text>{" "}
                  email address.
                </Text>
                <Button
                  type="submit"
                  mt="5"
                  bgColor={"gray.600"}
                  _hover={{ bgColor: "gray.700" }}
                  _active={{ bgColor: "gray.800" }}
                  color="white"
                  onClick={onClose}
                >
                  Yes, Send Me the Brochure!
                </Button>
              </Flex>
            ) : (
              <Signup
                initialRef={initialRef}
                helperMsg={"This will be used to send you the brochure."}
                toastMessage={"We have sent you an email with the brochure."}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
