import { useState } from "react";
import { Box, Text } from "@chakra-ui/layout";
import { Textarea } from "@chakra-ui/textarea";
import { Button } from "@chakra-ui/button";
import { db } from "../../services/firebase";
import { addDoc, collection } from "firebase/firestore";
import { useToast } from "@chakra-ui/toast";

const FeedbackForm = () => {
  const [message, setMessage] = useState("");
  const toast = useToast();
  const handleSubmit = (e) => {
    e.preventDefault();
    if (message.length === 0) {
      return toast({
        title: "Please, Provide some message!",
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
    addDoc(collection(db, "feeback"), { message: message })
      .then(() => {
        console.log("Noted!");
        setMessage("");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Box ml="1" mr="2" px="2">
      <Text fontSize="lg" fontWeight="semibold">
        Feedback
      </Text>
      <Text fontSize="xs">
        We are looking to improve and would love to hear from you in this
        anonymous feedback.
      </Text>
      <form onSubmit={handleSubmit}>
        <Box w="full">
          <Text fontSize="small" mb="8px" mt="1">
            Your Message :
          </Text>
          <Textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Type your response here..."
            size="sm"
          />
        </Box>
        <Button
          mt="1"
          w="120px"
          type="submit"
          bgColor={"gray.600"}
          _hover={{ bgColor: "gray.700" }}
          _active={{ bgColor: "gray.800" }}
          color="white"
        >
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default FeedbackForm;
