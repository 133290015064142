import { Box, Flex, Text } from "@chakra-ui/layout";
import React, { useEffect } from "react";
import notFound from "../assets/not-found.png";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import * as Scroll from "react-scroll";

const NotFound = () => {
  useEffect(() => {
    let scroll = Scroll.animateScroll;
    scroll.scrollToTop();
  }, []);
  return (
    <div>
      <Navbar />
      <Flex flexDir="column" align="center" justify="center">
        <Text mt="5" mb="5" fontSize="4xl" fontWeight="semibold">
          Oops!
        </Text>
        <Text mb="5">Looks like the page you are looking does not exit...</Text>
        <Text color="blue" textDecoration="underline" mb="10">
          <Link to="/">Go Back to Home</Link>
        </Text>
        <Box mx="5">
          <img
            src={notFound}
            alt="The page you are looking for was not found"
          />
        </Box>
      </Flex>
      <Footer />
    </div>
  );
};

export default NotFound;
