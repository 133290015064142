import { useBreakpointValue } from "@chakra-ui/media-query";
import { Flex, Text } from "@chakra-ui/layout";

const Description = ({ heading, paragraph, secondPara }) => {
  const marginX = useBreakpointValue({ base: "40px", md: "100px" });
  return (
    <Flex align="center" justify="center" flexDir="column" my="10">
      <Text mt="20px" mb="20px" fontSize="4xl" align="center">
        {heading}
      </Text>
      <Text mx={marginX} align="center" mt="10px" mb="30px">
        {paragraph}
      </Text>
      {secondPara && (
        <Text mx={marginX} align="center" mt="10px" mb="30px" fontWeight="bold">
          {secondPara}
        </Text>
      )}
    </Flex>
  );
};

export default Description;
