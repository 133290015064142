import Navbar from "../components/Navbar";
import SignUpForm from "../components/Forms/SignupForm";
import { Flex, Text } from "@chakra-ui/layout";

const SignUp = () => {
  return (
    <>
      <Navbar />
      <Flex flexDir="column" w="100%" justify="center" align="center">
        <Text mt="10" mb="5" fontSize="3xl" fontWeight="bold">
          Sign Up for a New Account
        </Text>
        <Flex flexDir="column" w="300px" justify="center">
          <SignUpForm />
        </Flex>
      </Flex>
    </>
  );
};

export default SignUp;
