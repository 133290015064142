import { useRef } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import Signup from "../Forms/SignupForm";

export default function Email({ isOpen, onClose }) {
  const initialRef = useRef();

  return (
    <>
      <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={onClose}
        size="lg"
        borderRadius="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create your account</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Signup
              initialRef={initialRef}
              toastMessage={"Your Account has been created."}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
