import {
  Box,
  Link,
  useBreakpointValue,
  Stack,
  Text,
  Flex,
  useColorModeValue,
  Image,
} from "@chakra-ui/react";
import FeedbackForm from "./Forms/FeedbackForm";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import Logo from "../assets/logo.png";

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={"500"} fontSize={"lg"} mb={2}>
      {children}
    </Text>
  );
};

export default function LargeWithLogoCentered() {
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <Box
      bg={useColorModeValue("gray.50", "gray.900")}
      color={useColorModeValue("gray.700", "gray.200")}
      mt="50px"
      w="full"
      pt={isMobile ? "20px" : "50px"}
      overflow="hidden"
    >
      <Stack
        direction={isMobile ? "column" : "row"}
        justify="center"
        align="center"
        w="inherit"
        spacing={!isMobile && "10"}
      >
        <Flex
          direction={isMobile ? "column" : "row"}
          w="100%"
          mt={isMobile ? "5" : "0"}
          wrap="wrap"
          justify={"space-between"}
        >
          {!isMobile ? (
            <>
              <Flex flexDir="column" justify="space-between" ml={"5"} mr={"20"}>
                <ListHeader>Find Us</ListHeader>
                <Text pb="10">
                  C/7-8, Barcelona multiple business
                  <br /> complex, Odhav ring road, Odhav,
                  <br /> Ahmedabad
                  <br />
                  Pin: 382 415
                  <br />
                  Contact: 87801 75439
                </Text>
              </Flex>
              <Flex flexDir="column" justify="space-between" mr={"20"}>
                <ListHeader>Company</ListHeader>
                <Link href={"/about"}>About Us</Link>
                <Link href={"/contact"}>Contact</Link>
                <Link href={"#"}>Partners</Link>
                <Link href={"#"}>Cookies</Link>
                <Link href={"#"}>Privacy</Link>
                <Link href={"#"}>Terms</Link>
              </Flex>
              <Flex flexDir={"column"} w="95px">
                <ListHeader>Follow Us</ListHeader>
                <Flex align="center" justify="space-start">
                  <FaFacebook
                    style={{
                      height: 20,
                      width: "auto",
                    }}
                  />
                  <Link href={"#"} ml="1">
                    Facebook
                  </Link>
                </Flex>
                <Flex align="center" justify="flex-start">
                  <FaInstagram
                    style={{
                      height: 20,
                      width: "auto",
                    }}
                  />
                  <Link
                    target={"_blank"}
                    href={"https://www.instagram.com/bianco_official/"}
                    ml="1"
                  >
                    Instagram
                  </Link>
                </Flex>
                <Flex align="center" justify="flex-start">
                  <FaLinkedin
                    style={{
                      height: 20,
                      width: "auto",
                    }}
                  />
                  <Link href={"#"} ml="1">
                    Linkedin
                  </Link>
                </Flex>
                <Flex align="center" justify="flex-start">
                  <FaTwitter
                    style={{
                      height: 20,
                      width: "auto",
                    }}
                  />
                  <Link href={"#"} ml="1">
                    Twitter
                  </Link>
                </Flex>
              </Flex>
            </>
          ) : (
            <Flex flexDir="column">
              <Flex flexDir="column" mx="2">
                <ListHeader>Find Us</ListHeader>
                <Text>
                  C/7-8, Barcelona multiple business complex, Odhav ring road, Odhav, Ahmedabad Pin:
                  382 415 Contact: 98255 85923
                </Text>
              </Flex>
              <Flex justify="flex-start" mt="5" w="full">
                <Flex flexDir="column" justify="center" mx="3" mr="10">
                  <ListHeader>Company</ListHeader>
                  <Link href={"/about"}>About Us</Link>
                  <Link href={"/contact"}>Contact</Link>
                  <Link href={"#"}>Partners</Link>
                  <Link href={"#"}>Cookies</Link>
                  <Link href={"#"}>Privacy</Link>
                  <Link href={"#"}>Terms</Link>
                </Flex>
                <Flex flexDir={"column"} w="95px">
                  <ListHeader>Follow Us</ListHeader>
                  <Flex align="center" justify="space-start">
                    <FaFacebook />
                    <Link href={"#"} ml="1">
                      Facebook
                    </Link>
                  </Flex>
                  <Flex align="center" justify="flex-start">
                    <FaInstagram />
                    <Link
                      target={"_blank"}
                      href={"https://www.instagram.com/bianco_official/"}
                      ml="1"
                    >
                      Instagram
                    </Link>
                  </Flex>
                  <Flex align="center" justify="flex-start">
                    <FaLinkedin />
                    <Link href={"#"} ml="1">
                      Linkedin
                    </Link>
                  </Flex>
                  <Flex align="center" justify="flex-start">
                    <FaTwitter />
                    <Link href={"#"} ml="1">
                      Twitter
                    </Link>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          )}
          <Flex align={"flex-start"} mt={isMobile && "5"}>
            <FeedbackForm />
          </Flex>
        </Flex>
      </Stack>

      <Box py={10}>
        <Flex
          align={"center"}
          _before={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: useColorModeValue("gray.200", "gray.700"),
            flexGrow: 1,
            mr: 8,
          }}
          _after={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: useColorModeValue("gray.200", "gray.700"),
            flexGrow: 1,
            ml: 8,
          }}
        >
          <Image src={Logo} width="175px" />
        </Flex>
        <Text fontSize={"sm"} textAlign={"center"}>
          © 2021 Bianco Modular Furniture. All rights reserved.
        </Text>
      </Box>
    </Box>
  );
}
