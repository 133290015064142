import React, { useEffect } from "react";
import { Box, Flex, Text } from "@chakra-ui/layout";
import Navbar from "../components/Navbar";
import SingleSlide from "../components/SingleSlide";
import { useBreakpointValue } from "@chakra-ui/media-query";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import Footer from "../components/Footer";
import * as Scroll from "react-scroll";

const About = () => {
  const adjustHeight = useBreakpointValue({ base: "60px", md: "160px" });
  const marginX = useBreakpointValue({ base: "40px", md: "100px" });
  useEffect(() => {
    let scroll = Scroll.animateScroll;
    scroll.scrollToTop();
  });
  return (
    <>
      <Navbar />
      <Box w="100%" maxH={`calc(100vh - ${adjustHeight})`} overflow="hidden">
        <SingleSlide
          url={`https://images.unsplash.com/photo-1556912173-3bb406ef7e77?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1740&q=80`}
          text={"About Us"}
        />
      </Box>
      <Box>
        <Text
          mt="20px"
          mb="20px"
          fontSize="4xl"
          align="center"
          fontWeight="semibold"
        >
          We Are Bianco
        </Text>
        <Text mx={marginX} align="center" mb="10px">
          A leading manufacturer of Modular Furniture.
        </Text>
        <Flex align="center" justify="center" w="full" mt="30px">
          <hr
            style={{ backgroundColor: "black", height: "2px", width: "350px" }}
          />
        </Flex>
        <Text
          mt="20px"
          mb="20px"
          fontSize="4xl"
          align="center"
          fontWeight="semibold"
        >
          Why Bianco?
        </Text>
        <Text mx={marginX} align="center" mb="10px">
          We're on a mission to make the well-designed, perfect combination of
          quality, expertise, and experience.
        </Text>
        <Text mx={marginX} align="center" mb="10px">
          We take pride and value the beauty and craftsmanship of our work and
          strive to provide your home with the touch that will exceed your
          expectations.
        </Text>
        <Text mx={marginX} align="center" mb="10px">
          We adhere to our strict ethical manufacturing practices and maintains
          the required standards.
        </Text>

        <Text mx={marginX} align="center" mb="30px">
          Responsibility and ethical manufacturing are core values; we feel
          strongly about building personal, long-lasting relationships with our
          customers, offering a transparent look into the process.{" "}
        </Text>
      </Box>
      <Flex align="center" justify="center" w="full">
        <hr
          style={{ backgroundColor: "black", height: "2px", width: "350px" }}
        />
      </Flex>
      <Box>
        <Text
          align="center"
          mx={marginX}
          mt="50px"
          fontWeight="semibold"
          fontSize="2xl"
        >
          Some Frequently Asked Questions
        </Text>
      </Box>
      <Box mx={marginX} mt="30px">
        <Accordion defaultIndex={[0]} allowMultiple>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Do you work with companies, designers, real estate developers,
                  hotels, and other professionals?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Yes, we are happy to provide our partners with exclusive prices
              and services. Contact us for more information!
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>
      <Footer></Footer>
    </>
  );
};

export default About;
