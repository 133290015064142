import React from "react";
import Slider from "react-slick";
import { Box, useBreakpointValue } from "@chakra-ui/react";
import SingleSlide from "./SingleSlide";

const HeroSlider = () => {
  const adjustHeight = useBreakpointValue({ base: "60px", md: "80px" });
  const settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    autoplay: true,
    autoplaySpeed: 2000,
    fade: true,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <Box w="100%" maxH={`calc(100vh - ${adjustHeight})`} overflow="hidden">
        <Box
          minH="100vh"
          transition="transform 0.5s"
          _hover={{ transform: "scale(1.05)" }}
        >
          <Slider {...settings}>
            <div>
              <SingleSlide
                url={`https://images.unsplash.com/photo-1524758631624-e2822e304c36?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2900&q=80`}
                text={`Hidden Treasure`}
              />
            </div>
            <div>
              <SingleSlide
                url={`https://images.unsplash.com/photo-1538688525198-9b88f6f53126?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1100&q=80`}
                text={`Calm Retreat`}
              />
            </div>
            <div>
              <SingleSlide
                url={`https://images.unsplash.com/photo-1556228453-efd6c1ff04f6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1400&q=80`}
                text={`Absolute Wonders`}
              />
            </div>
          </Slider>
        </Box>
      </Box>
    </>
  );
};

export default HeroSlider;
