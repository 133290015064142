import { useEffect } from "react";
import * as Scroll from "react-scroll";
import ContactForm from "../components/Forms/ContactForm";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Box, Divider, Flex, Text } from "@chakra-ui/layout";
import { useBreakpointValue } from "@chakra-ui/media-query";

const Contact = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false });
  useEffect(() => {
    let scroll = Scroll.animateScroll;
    scroll.scrollToTop();
  });
  return (
    <>
      <Navbar />
      <Text align="center" mt="5" fontSize="3xl">
        Contact Us
      </Text>
      <Flex justify="center" w="full" flexDirection={isMobile ? "column" : "row"}>
        <ContactForm />
        {!isMobile && <Divider orientation="vertical" h="350px" />}
        <Box w="300px" mt="5" ml="3">
          <Text fontSize="xl" fontWeight="medium">
            Contact Information
          </Text>
          <Divider w="350px" mt="5" />
          <Text fontSize="md" mt="2">
            Mobile Number: 87801 75439
          </Text>
          <Divider w="350px" mt="5" />
          <Flex>
            <Text fontSize="md" mt="2">
              Address:
            </Text>
            <Text fontSize="md" mt="2" ml="1">
              C/7-8, Barcelona multiple business complex, Odhav ring road, Odhav, Ahmedabad
              <br />
              Pin Code: 382 415
            </Text>
          </Flex>
        </Box>
      </Flex>
      <Footer />
    </>
  );
};

export default Contact;
