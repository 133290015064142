import { useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import * as Scroll from "react-scroll";
import SingleSlide from "../components/SingleSlide";
import { Box, Text } from "@chakra-ui/layout";
import { useBreakpointValue } from "@chakra-ui/media-query";

const Kitchen = () => {
  const adjustHeight = useBreakpointValue({ base: "60px", md: "160px" });
  const marginX = useBreakpointValue({ base: "40px", md: "100px" });
  useEffect(() => {
    let scroll = Scroll.animateScroll;
    scroll.scrollToTop();
  });
  return (
    <div>
      <Navbar />
      <Box w="100%" maxH={`calc(100vh - ${adjustHeight})`} overflow="hidden">
        <SingleSlide
          url={`https://images.unsplash.com/photo-1556909172-54557c7e4fb7?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1740&q=80`}
          text={"Kitchen"}
        />
      </Box>
      <Box mx={marginX} mt="10">
        <Text>
          &nbsp; &nbsp;&nbsp;&nbsp;&nbsp; It is amazing how kitchens have gone
          from being a mere hob to bring the beauty of a home. The kitchen has
          now become a work of art that gives the allure of a home; a place of
          enjoyment and celebration; a place that defines the delicacy and the
          refined choice of the owner. That is why a transparent kitchen is now
          an elegant and the most modern modular kitchen, technologically
          equipped and with an eloquent design: the centerpiece of the house.
        </Text>
        <Text mt="5">
          &nbsp; &nbsp;&nbsp;&nbsp;&nbsp; Bianco, ​​one of the leading providers
          and pioneers in kitchen solutions offers you modular kitchens that are
          comfortable, uniquely designed, elegant, and stylish. Drawing on a
          decade of experience and a dedicated team of highly-trained and
          creative designers and technicians.
        </Text>
        <Text mt="5">
          &nbsp; &nbsp;&nbsp;&nbsp;&nbsp; Here at Bianco we envision your
          kitchen through your eyes and make it exactly how you want it to be.
          We strongly believe that building a kitchen is a matter of finding the
          right balance between art and science.
        </Text>
        <Text mt="5">
          &nbsp; &nbsp;&nbsp;&nbsp;&nbsp; We create a beauty that makes the
          kitchen of the times with its elegance, use of space, and
          functionality something special.
        </Text>
        <Text mt="5">
          &nbsp; &nbsp;&nbsp;&nbsp;&nbsp; Explore through the pages of this
          gallery to see, a fascinating collection of kitchen art, a set of the
          best that can fit into your kitchen and turn it into a fantastic,
          stylish, and practical kitchen. designed and elegantly practical.
        </Text>
      </Box>
      <Footer />
    </div>
  );
};

export default Kitchen;
