import { useState } from "react";
import { Button } from "@chakra-ui/button";
import { Box, Flex } from "@chakra-ui/layout";
import EmailModal from "./Modals/Email";

const BrochureDownload = () => {
  const [showEmail, setShowEmail] = useState(false);
  return (
    <Box mx="2">
      <Flex
        align="center"
        justify="center"
        my="10"
        w="calc(100vw-200px)"
        backgroundSize="cover"
        bgGradient="linear(to-r,  #0f2027, #203a43, #2c5364)"
        backgroundRepeat="no-repeat"
        h="300px"
        borderRadius="xl"
      >
        <Button px="10" onClick={() => setShowEmail(true)} size="lg">
          Download Brochure
        </Button>
        <EmailModal isOpen={showEmail} onClose={() => setShowEmail(false)} />
      </Flex>
    </Box>
  );
};

export default BrochureDownload;
