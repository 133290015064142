import React, { useEffect, useState } from "react";
import { Flex } from "@chakra-ui/layout";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Route, useRouteMatch } from "react-router-dom";
import ProductDetail from "../components/Products/Details";
import { useMeta } from "../contexts/MetaContext";
import ProductList from "../components/Products/List";
import { db } from "../services/firebase";
import { doc, getDocFromServer } from "@firebase/firestore";
const Products = () => {
  const [productList, setProductList] = useState([]);
  const { reloadPage, setReloadPage } = useMeta();
  const { path } = useRouteMatch();

  const getData = () => {
    const docRef = doc(db, "products", "all");
    getDocFromServer(docRef).then((result) => {
      setProductList(result.data().products.reverse());
    });
  };

  useEffect(() => {
    if (reloadPage) {
      setReloadPage(false);
      window.location.reload();
    }
    if (productList.length === 0) {
      getData();
    }
  }, [reloadPage, setReloadPage, productList]);
  return (
    <>
      <Flex flexDir="column">
        <Navbar />
        <Route path={path} exact>
          <ProductList list={productList} />
        </Route>
        <Route path={`${path}/:singleProduct`} exact>
          <ProductDetail />
        </Route>
        <Footer />
      </Flex>
    </>
  );
};

export default Products;
