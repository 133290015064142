import { Box, Flex, Text } from "@chakra-ui/layout";
import { useState, useEffect } from "react";
import comingSoon from "../assets/coming-soon.png";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import {
  FormControl,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { Button } from "@chakra-ui/button";
import { useToast } from "@chakra-ui/toast";
import * as Scroll from "react-scroll";
import { useBreakpointValue } from "@chakra-ui/media-query";

const ComingSoon = () => {
  const [email, setEmail] = useState("");
  const isMobile = useBreakpointValue({ base: true, md: false });
  const toast = useToast();
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(email);
    setEmail("");
    return toast({
      title: "Noted!",
      description: "We will notify you when there is an upadate.",
      duration: 5000,
      position: "top",
      status: "success",
      isClosable: true,
    });
  };
  useEffect(() => {
    let scroll = Scroll.animateScroll;
    scroll.scrollToTop();
  }, []);
  return (
    <div>
      <Navbar />
      <Flex flexDir="column" align="center" justify="center">
        <Text mt="5" mb="5" fontSize="4xl" fontWeight="semibold">
          Coming Soon...
        </Text>
        <Text mb="5" align="center" mx="5">
          This page is under constrution. <br /> Enter your email address here
          and We’ll notify you as soon as it is available.
        </Text>
        <form onSubmit={(e) => handleSubmit(e)}>
          <Flex
            mb="10"
            align="center"
            w={isMobile ? "full" : "450px"}
            justify="space-between"
            flexDir={isMobile && "column"}
          >
            <FormControl id="email" w="350px" isRequired>
              <FormLabel>Email address</FormLabel>
              <Input
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <FormHelperText>
                This is will be used to send you updates.
              </FormHelperText>
            </FormControl>
            <Button
              type="submit"
              color="white"
              bg="blackAlpha.700"
              _hover={{ bgColor: "blackAlpha.800" }}
              _active={{ bgColor: "blackAlpha.900" }}
              mt={isMobile && "3"}
            >
              Enter
            </Button>
          </Flex>
        </form>
        <Box mx="5">
          <img
            src={comingSoon}
            alt="The page you are looking for was not found"
          />
        </Box>
      </Flex>
      <Footer />
    </div>
  );
};

export default ComingSoon;
