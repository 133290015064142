import { useEffect } from "react";
import { Flex, Box, Skeleton } from "@chakra-ui/react";
import * as Scroll from "react-scroll";
import Single from "./Single";

function List({ list: productList }) {
  useEffect(() => {
    let scroll = Scroll.animateScroll;
    scroll.scrollToTop();
  }, [productList.length]);

  return (
    <>
      {productList[0] ? (
        <Flex
          w="full"
          alignItems="center"
          h="full"
          justifyContent="center"
          wrap="wrap"
          mb="10"
        >
          {productList ? (
            productList.map((product, i) => {
              return <Single key={i} product={product} />;
            })
          ) : (
            <>
              <Flex
                h="60vh"
                w="100%"
                align="center"
                justifyContent="center"
                mt="120px"
              >
                <Skeleton h="xl">
                  <Box w="90vw"></Box>
                </Skeleton>
              </Flex>
            </>
          )}
        </Flex>
      ) : (
        <>
          <Flex
            h="60vh"
            w="100%"
            align="center"
            justifyContent="center"
            mt="120px"
          >
            <Skeleton h="full">
              <Box w="90vw"></Box>
            </Skeleton>
          </Flex>
        </>
      )}
    </>
  );
}

export default List;
