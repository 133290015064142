import {
  Box,
  Flex,
  HStack,
  Text,
  VStack,
  IconButton,
  Button,
  Image,
  Tooltip,
  Collapse,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import Logo from "../assets/logo.png";
import { Link as RouterLink } from "react-router-dom";
import { IconContext } from "react-icons";
import { AiOutlineHeart, AiOutlineMenu } from "react-icons/ai";
import { GrClose } from "react-icons/gr";
import { useAuth } from "../contexts/AuthContext";

const Navbar = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { isOpen, onToggle } = useDisclosure();
  const { currentUser, logOut } = useAuth();
  return isMobile ? (
    <Box
      w="full"
      bg="white"
      zIndex="5"
      pos="sticky"
      top="0"
      left="0"
      borderColor="black"
      borderBottomWidth="1px"
    >
      <Box w="90vw">
        <Flex
          h="60px"
          background="#fff"
          align="center"
          w="full"
          mx="5"
          justify="space-between"
        >
          <RouterLink to="/">
            <Flex w="150px" justify="space-between" align="center">
              <Image src={Logo} alt="Logo" />
            </Flex>
          </RouterLink>
          <Box>
            <IconContext.Provider value={{ size: "1.3em" }}>
              <IconButton
                variant="ghost"
                p="2"
                aria-label="Open Menu"
                size="md"
                _focus={{
                  outline: "none",
                }}
                onClick={onToggle}
                icon={isOpen ? <GrClose /> : <AiOutlineMenu />}
              />
            </IconContext.Provider>
          </Box>
        </Flex>
        <Collapse in={isOpen} animateOpacity>
          <Box p="30px" top="60px" left="0" zIndex="5" pos="sticky">
            <VStack align="flex-start" spacing={2}>
              <RouterLink to="/products">
                <Text>Products</Text>
              </RouterLink>
              <RouterLink to="/about">
                <Text>About Us</Text>
              </RouterLink>
              <RouterLink to="/contact">
                <Text>Contact Us</Text>
              </RouterLink>
              <hr
                style={{
                  backgroundColor: "black",
                  color: "black",
                  height: "1px",
                  width: "100%",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              />
              <RouterLink to="/wishlist">
                <Text>Your Wishlist</Text>
              </RouterLink>
              {typeof currentUser === "string" ? (
                <Box w="200px">
                  <RouterLink to="/sign-up">
                    <Button
                      bg="blackAlpha.700"
                      color="white"
                      _hover={{ bg: "blackAlpha.800" }}
                      _active={{ bg: "blackAlpha.900" }}
                    >
                      Sign Up
                    </Button>
                  </RouterLink>
                  <RouterLink to="/log-in">
                    <Button
                      bg="blackAlpha.700"
                      color="white"
                      _hover={{ bg: "blackAlpha.800" }}
                      _active={{ bg: "blackAlpha.900" }}
                      ml="3"
                    >
                      Log In
                    </Button>
                  </RouterLink>
                </Box>
              ) : (
                <>
                  <Button
                    onClick={() => logOut()}
                    bg="blackAlpha.700"
                    color="white"
                    _hover={{ bg: "blackAlpha.800" }}
                    _active={{ bg: "blackAlpha.900" }}
                  >
                    Log Out
                  </Button>
                </>
              )}
            </VStack>
          </Box>
        </Collapse>
      </Box>
    </Box>
  ) : (
    <Flex
      w="full"
      zIndex="5"
      pos="sticky"
      top="0"
      left="0"
      bg="#fff"
      borderColor="black"
      borderBottomWidth="1px"
    >
      <Flex
        h="80px"
        background="#fff"
        align="center"
        w="full"
        mx="10"
        justify="space-between"
      >
        <Box>
          <RouterLink to="/">
            <Image src={Logo} maxW={"175px"} overflow="hidden" alt="Logo" />
          </RouterLink>
        </Box>
        <HStack spacing="25px" align="center" h="inherit" justify="center">
          <RouterLink to="/products">
            <Text>Products</Text>
          </RouterLink>
          <RouterLink to="/about">
            <Text>About Us</Text>
          </RouterLink>
          <RouterLink to="/contact">
            <Text>Contact Us</Text>
          </RouterLink>
          {typeof currentUser !== "string" ? (
            <Button onClick={() => logOut()}>Log Out</Button>
          ) : (
            <>
              <RouterLink to="/sign-up">Sign Up</RouterLink>
              <RouterLink to="/log-in">Log In</RouterLink>
            </>
          )}
          <IconContext.Provider value={{ size: "1.2em" }}>
            <Tooltip label="See Your Wishlist!" hasArrow placement="left">
              <RouterLink to="/wishlist">
                <IconButton
                  variant="outline"
                  size="lg"
                  icon={<AiOutlineHeart />}
                />
              </RouterLink>
            </Tooltip>
          </IconContext.Provider>
        </HStack>
      </Flex>
    </Flex>
  );
};

export default Navbar;
