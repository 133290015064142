import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import OnlyPublic from "./components/OnlyPublic";
import Home from "./pages/Home";
import About from "./pages/About";
import Products from "./pages/Products";
import Contact from "./pages/Contact";
import NotFound from "./pages/NotFound";
import Wishlist from "./pages/Wishlist";
import AuthProvider from "./contexts/AuthContext";
import MetaProvider from "./contexts/MetaContext";
import SignUp from "./pages/SignUp";
import LogIn from "./pages/LogIn";
import ComingSoon from "./pages/ComingSoon";
import Kitchen from "./pages/Kitchen";

const App = () => {
  return (
    <>
      <ChakraProvider>
        <AuthProvider>
          <MetaProvider>
            <Router>
              <Switch>
                <Route path="/products" component={Products} />
                <Route path="/wishlist" component={Wishlist} />
                <OnlyPublic path="/log-in" component={LogIn} />
                <OnlyPublic path="/sign-up" component={SignUp} />
                <Route path="/coming-soon" component={ComingSoon} />
                <Route path="/about" exact component={About} />
                <Route path="/kitchen" component={Kitchen} />
                <Route path="/contact" component={Contact} />
                <Route path="/" exact component={Home} />
                <Route component={NotFound} />
              </Switch>
            </Router>
          </MetaProvider>
        </AuthProvider>
      </ChakraProvider>
    </>
  );
};

export default App;
