import { Button } from "@chakra-ui/button";
import {
  FormControl,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { Box, Flex, Stack, Text } from "@chakra-ui/layout";
import { useBreakpointValue } from "@chakra-ui/media-query";
import { Textarea } from "@chakra-ui/textarea";
import { useState } from "react";
import { useToast } from "@chakra-ui/toast";
import { addDoc, collection } from "@firebase/firestore";
import { db } from "../../services/firebase";

const ContactForm = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [organization, setOrganization] = useState("");
  const [message, setMessage] = useState("");
  const toast = useToast();
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name || !email || !contactNumber) {
      return toast({
        title: "Something isn't right!",
        description: "Please, Check the information again.",
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
    if (contactNumber.length !== 10) {
      return toast({
        title: "Contact Number should only be 10 characters",
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
    addDoc(collection(db, "contact"), {
      name,
      email,
      contactNumber,
      organization,
      message,
      contacted: false,
    })
      .then(() => {
        console.log("Noted!");
        setContactNumber("");
        setName("");
        setEmail("");
        setOrganization("");
        setMessage("");
        return toast({
          title: "Success!",
          description:
            "We have received your details and will contact you soon.",
          status: "success",
          position: "top",
          isClosable: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Flex flexDir="column" align="center" justify="center" mx="5">
      <form onSubmit={handleSubmit}>
        <Stack
          direction={isMobile ? "column" : "row"}
          mt="10"
          w="full"
          spacing={"4"}
          justify="center"
        >
          <Box>
            <Stack direction={isMobile ? "column" : "row"}>
              <FormControl isRequired w="350px">
                <FormLabel>Full Name</FormLabel>
                <Input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>
              <FormControl isRequired w="350px">
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <FormHelperText>
                  This will be used to contact you.
                </FormHelperText>
              </FormControl>
            </Stack>
            <Stack direction={isMobile ? "column" : "row"} mt="5">
              <FormControl isRequired w="350px">
                <FormLabel>Contact Number</FormLabel>
                <Input
                  type="number"
                  value={contactNumber}
                  onChange={(e) => setContactNumber(e.target.value)}
                />
              </FormControl>
              <FormControl w="350px">
                <FormLabel>Organization</FormLabel>
                <Input
                  type="text"
                  value={organization}
                  onChange={(e) => setOrganization(e.target.value)}
                />
              </FormControl>
            </Stack>
            <Stack direction={isMobile ? "column" : "row"} mt="5">
              <Box w="full">
                <Text mb="8px">Your Message :</Text>
                <Textarea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Type your response here..."
                  size="sm"
                />
              </Box>
            </Stack>
            <Flex justify="flex-end">
              <Button
                mt="2"
                w="120px"
                type="submit"
                bgColor={"gray.600"}
                _hover={{ bgColor: "gray.700" }}
                _active={{ bgColor: "gray.800" }}
                color="white"
              >
                Submit
              </Button>
            </Flex>
          </Box>
        </Stack>
      </form>
    </Flex>
  );
};

export default ContactForm;
