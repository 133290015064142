import { useEffect } from "react";
import Navbar from "../components/Navbar";
import { useMeta } from "../contexts/MetaContext";
import { useAuth } from "../contexts/AuthContext";
import ProductList from "../components/Products/List";
import { doc, getDocFromServer } from "@firebase/firestore";
import { db } from "../services/firebase";
import { Box, Flex, Text } from "@chakra-ui/layout";
import notFound from "../assets/not-found.png";
import Footer from "../components/Footer";

const Wishlist = () => {
  const { wishlist, setWishlist } = useMeta();
  const { currentUser } = useAuth();

  useEffect(() => {
    function getWishlist() {
      if (typeof currentUser !== "string") {
        const docRef = doc(db, "users", currentUser.uid);
        getDocFromServer(docRef).then((result) => {
          setWishlist(result.data().wishlist);
        });
      }
    }
    getWishlist();
  }, [currentUser, setWishlist]);
  return (
    <Flex flexDir="column" align="center" w="full">
      <Navbar />
      <Flex w="full" flexDir="column" align="center">
        <Text fontSize="3xl" mt="3">
          Your Wishlist
        </Text>
      </Flex>
      {typeof wishlist === "object" && wishlist.length ? (
        <>
          <ProductList list={wishlist}></ProductList>
        </>
      ) : (
        <>
          {" "}
          <Box mx="5">
            <Text align="center" my="5">
              Uh Oh! It's seems a bit empty in here!
            </Text>
            <img
              src={notFound}
              alt="The page you are looking for was not found"
            />
          </Box>
        </>
      )}
      <Footer />
    </Flex>
  );
};

export default Wishlist;
