import { useRef, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import {
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Box,
  useToast,
  Button,
  Flex,
  Textarea,
  Text,
} from "@chakra-ui/react";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../services/firebase";

export default function Contact({ isOpen, onClose }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [organization, setOrganization] = useState("");
  const [message, setMessage] = useState("");
  const toast = useToast();
  const initialRef = useRef();
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name || !email || !contactNumber) {
      return toast({
        title: "Something isn't right!",
        description: "Please, Check the information again.",
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
    if (contactNumber.length !== 10) {
      return toast({
        title: "Contact Number should only be 10 characters",
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
    addDoc(collection(db, "contact"), {
      name,
      email,
      contactNumber,
      organization,
      message,
      contacted: false,
    })
      .then(() => {
        console.log("Noted!");
        setContactNumber("");
        setName("");
        setEmail("");
        setOrganization("");
        setMessage("");
        return toast({
          title: "Success!",
          description:
            "We have received your details and will contact you soon.",
          status: "success",
          position: "top",
          isClosable: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={onClose}
        size="lg"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Inquire Now</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <form onSubmit={handleSubmit}>
              <FormControl mb="2" isRequired>
                <FormLabel>Full Name</FormLabel>
                <Input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>
              <FormControl mb="3" isRequired>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <FormHelperText>
                  This will be used to contact you.
                </FormHelperText>
              </FormControl>
              <FormControl mb="3" isRequired>
                <FormLabel>Contact Number</FormLabel>
                <Input
                  type="number"
                  value={contactNumber}
                  onChange={(e) => setContactNumber(e.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Organization</FormLabel>
                <Input
                  type="text"
                  value={organization}
                  onChange={(e) => setOrganization(e.target.value)}
                />
              </FormControl>
              <Box mt="2">
                <Text>Your Message</Text>
                <Textarea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Type your response here..."
                  size="sm"
                />
              </Box>
              <Flex justify="flex-end" mt="4">
                <Button
                  mt="2"
                  w="120px"
                  type="submit"
                  bgColor={"gray.600"}
                  _hover={{ bgColor: "gray.700" }}
                  _active={{ bgColor: "gray.800" }}
                  color="white"
                >
                  Submit
                </Button>
              </Flex>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
