import { Box, Flex } from "@chakra-ui/layout";
import Navbar from "../components/Navbar";
import HeroSlider from "../components/HeroSlider";
import Description from "../components/Description";
import Footer from "../components/Footer";
import HomeSlide from "../components/HomeSlide";
import Testimonials from "../components/Testimonials";
import { Element } from "react-scroll";
import BrochureDownload from "../components/BrochureDownload";
import TabPhoto from "../components/TabPhoto";
import { useBreakpointValue } from "@chakra-ui/media-query";

export default function Home() {
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <Box>
      <HomeSlide
        url={`https://images.unsplash.com/photo-1524758631624-e2822e304c36?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2900&q=80`}
        showDown
      />
      <Element name="stickyNavbar" />
      <Navbar />
      <HeroSlider />
      <Description
        heading="Modern Designs for Modern Minds"
        paragraph="We are passionate about what we do and take pride in every individual project, whether it's 
        creating exceptional kitchens or dream-come-true dressing rooms. We design, make, and finish 
        every part to the greatest quality, using only the finest equipment and resources, based on our 
        experience of decades."
      />
      <Flex
        h={isMobile ? "1000px" : "500px"}
        mb="5"
        flexDir={isMobile ? "column" : "row"}
      >
        <TabPhoto
          toURL="/kitchen"
          isMobile={isMobile}
          photoURL="https://images.unsplash.com/photo-1565538810643-b5bdb714032a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=687&q=80"
          title="Kitchen"
        />
        <TabPhoto
          toURL="/coming-soon"
          title="Wardrobe"
          isMobile={isMobile}
          photoURL="https://images.unsplash.com/photo-1611048268330-53de574cae3b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1744&q=80"
        />
        <Box w="3" />
      </Flex>
      <Testimonials />
      <Description
        heading="Catalogue"
        paragraph="With Bianco, you will get the chance to feel the best craftsmanship and design
         technology of the highest quality furniture. A well-arranged home is all about innovative 
         designs solutions, Blending classicism and modernism, the purity of lines and tactile 
         appeal of textures, natural colors, and polished accents."
        secondPara={
          "Take a look at our catalog and get inspired by our mind-blown collection."
        }
      />
      <BrochureDownload />
      <Footer />
    </Box>
  );
}
