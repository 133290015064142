import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
} from "@firebase/auth";
import { auth, db } from "./firebase";
import { doc, setDoc, getDoc } from "firebase/firestore";

export function googleSignInPopup() {
  const provider = new GoogleAuthProvider();
  signInWithPopup(auth, provider)
    .then((result) => {
      const user = result.user;
      const userRef = doc(db, "users", `${user.uid.toString()}`);
      getDoc(userRef)
        .then((result) => {
          if (!result.exists()) {
            setDoc(userRef, {
              ...user.providerData[0],
              wishilist: [],
              createdAt: new Date().toString(),
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    })
    .catch((error) => {
      console.error(error);
    });
}

export function facebookSignInPopup() {
  const provider = new FacebookAuthProvider();
  signInWithPopup(auth, provider)
    .then((result) => {
      const user = result.user;
      console.log(user);
      const userRef = doc(db, "users", `${user.uid.toString()}`);
      getDoc(userRef)
        .then((result) => {
          if (!result.exists()) {
            setDoc(userRef, {
              ...user.providerData[0],
              wishilist: [],
              createdAt: new Date().toString(),
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    })
    .catch((error) => {
      console.error(error);
    });
}
