import React, { useState } from "react";
import {
  Flex,
  VStack,
  Heading,
  Divider,
  Button,
  useBreakpointValue,
  Text,
  useToast,
} from "@chakra-ui/react";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { useMeta } from "../../contexts/MetaContext";
import SignInProviderButtons from "../SignInProviderButtons";

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const formWidth = useBreakpointValue({ base: "350px", md: "400px" });
  const dividerLength = useBreakpointValue({ base: "350px", md: "500px" });
  const toast = useToast();
  const { logIn } = useAuth();
  const { setReloadPage } = useMeta();
  let history = useHistory();
  function handleSubmit(e) {
    setLoading(true);
    e.preventDefault();
    try {
      logIn(email, password).then((_) => {
        setReloadPage(true);
        history.push("/products");
      });
    } catch {
      toast({
        title: "Oops! Failed to log in.",
        position: "top",
        description: "Check your details and try again!",
        status: "error",
        duration: 9000,
        isClosable: true,
      }) && setLoading(false);
    }
  }

  return (
    <>
      <VStack>
        <Heading as="h2" size="lg" mt="10">
          Log In to your Account
        </Heading>
        <form onSubmit={handleSubmit}>
          <Flex flexDir="column" align="center">
            <FormControl mt={4} isRequired>
              <FormLabel>Email Address</FormLabel>
              <Input
                type="email"
                placeholder="name@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                w={formWidth}
              />
            </FormControl>
            <FormControl mt={3} mb={3} isRequired>
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                placeholder="Your Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
            <Button
              type="submit"
              bgColor={"gray.600"}
              _hover={{ bgColor: "gray.700" }}
              _active={{ bgColor: "gray.800" }}
              color="white"
              mt="4"
              isLoading={loading}
            >
              Log In
            </Button>
          </Flex>
        </form>
      </VStack>
      <Flex flexDir="column" align="center">
        <Divider my="3" w={dividerLength} />
        <Text align="center">Or Continue With</Text>
        <SignInProviderButtons />
      </Flex>
      <Flex align="center" w="100%" justify="center" flexDir="column">
        <Divider mt="5" w={dividerLength} />
        <VStack align="center" mt="2">
          <Text>Need an account?</Text>
          &nbsp;
          <Button as="span" variant="solid" color="black">
            <RouterLink to="/sign-up">Click here to sign up! </RouterLink>
          </Button>
        </VStack>
      </Flex>
    </>
  );
};

export default SignUp;
