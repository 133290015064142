import React, { useState, useEffect, useContext, createContext } from "react";
import { db } from "../services/firebase";
import { doc, getDocFromServer } from "firebase/firestore";
import { useAuth } from "./AuthContext";

const MetaContext = createContext();

export const useMeta = () => useContext(MetaContext);

const WishListProvider = ({ children }) => {
  const [wishlist, setWishlist] = useState([]);
  const [reloadPage, setReloadPage] = useState(false);
  const { currentUser } = useAuth();
  useEffect(() => {
    function getWishlist() {
      if (typeof currentUser !== "string") {
        const docRef = doc(db, "users", currentUser.uid);
        getDocFromServer(docRef)
          .then((result) => {
            setWishlist(result.data().wishlist);
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
    getWishlist();
  }, [currentUser]);

  const metaValue = {
    wishlist,
    reloadPage,
    setReloadPage,
    setWishlist,
  };

  return (
    <MetaContext.Provider value={metaValue}>{children}</MetaContext.Provider>
  );
};

export default WishListProvider;
