import {
  FormControl,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { Divider, Flex, Text, VStack } from "@chakra-ui/layout";
import { useState } from "react";
import { useBreakpointValue } from "@chakra-ui/react";
import { Button } from "@chakra-ui/button";
import { useToast } from "@chakra-ui/toast";
import { Link as RouterLink } from "react-router-dom";
import { auth, db } from "../../services/firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import SignInProviderButtons from "../SignInProviderButtons";

const Signup = ({ initialRef, helperMsg, toastMessage }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const formWidth = useBreakpointValue({ base: "300px", md: "450px" });

  async function signUpWithName(name, email, password) {
    try {
      const result = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = result.user;
      console.log(user);
      const userRef = doc(db, "users", `${user.uid.toString()}`);
      getDoc(userRef)
        .then((result_2) => {
          if (!result_2.exists()) {
            setDoc(userRef, {
              ...user.providerData[0],
              displayName: name,
              wishlist: [],
              createdAt: new Date().toString(),
            });
          }
        })
        .then(
          toast({
            title: "Email Sent!",
            description: toastMessage,
            duration: 9000,
            position: "top",
            status: "success",
            isClosable: true,
          }) && setLoading(false)
        )
        .catch((error) => {
          console.error(error);
        });
    } catch (error_1) {
      console.error(error_1);
      toast({
        title: "Could not create account!",
        description: "Please check your details and try again!",
        duration: 9000,
        position: "top",
        status: "error",
        isClosable: true,
      }) && setLoading(false);
    }
  }
  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    console.log(name, email, password);
    signUpWithName(name, email, password);
  }
  return (
    <>
      <VStack w="100%">
        <form onSubmit={handleSubmit} style={{ width: formWidth }}>
          <FormControl mt={4} mb={4} isRequired>
            <FormLabel>Name</FormLabel>
            <Input
              type="text"
              placeholder="Enter Your Name"
              value={name}
              ref={initialRef}
              onChange={(e) => setName(e.target.value)}
            />
          </FormControl>
          <FormControl mb={4} isRequired>
            <FormLabel>Email address</FormLabel>
            <Input
              type="email"
              placeholder="name@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {helperMsg && <FormHelperText>{helperMsg}</FormHelperText>}
          </FormControl>
          <FormControl mb={4} isRequired>
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              placeholder="Strong Password here"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormControl>
          <Flex w="100%" justify="center" mt="5">
            <Button
              type="submit"
              bgColor={"gray.600"}
              _hover={{ bgColor: "gray.700" }}
              _active={{ bgColor: "gray.800" }}
              color="white"
              isLoading={loading}
            >
              Create Account
            </Button>
          </Flex>
        </form>
        <Text>Or Continue With...</Text>
        <SignInProviderButtons />
        <Divider mt="5" />
        <VStack mt="5" align="center" w="100%">
          <Text>Already Have an account?</Text>
          &nbsp;
          <Button as="span" variant="solid" color="black">
            <RouterLink to="/log-in">Click here to log in! </RouterLink>
          </Button>
        </VStack>
      </VStack>
    </>
  );
};

export default Signup;
