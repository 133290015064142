import { Image } from "@chakra-ui/image";
import { Flex, Text } from "@chakra-ui/layout";
import { Link } from "react-router-dom";

const TabPhoto = ({ toURL, isMobile, photoURL, title }) => {
  return (
    <Flex
      w={isMobile ? "clac(100% - 24px)" : "50%"}
      as={Link}
      justifyContent="center"
      h="500px"
      mt="3"
      ml="3"
      mr={isMobile && "3"}
      to={`${toURL}`}
      pos="relative"
      overflow={"hidden"}
    >
      <Image
        filter="brightness(50%)"
        objectFit="cover"
        w="100%"
        bgGradient="linear(to-r, gray, red)"
        transition="transform 0.5s"
        _hover={{ transform: "scale(1.1)" }}
        src={photoURL}
        borderRadius="lg"
      />
      <Flex pos="absolute" top="50%">
        <Text
          zIndex="2"
          color="#fff"
          fontSize={isMobile ? "2xl" : "3xl"}
          px="10"
          py="1"
        >
          {title}
        </Text>
      </Flex>
    </Flex>
  );
};

export default TabPhoto;
