import { useEffect, useState } from "react";
import {
  Box,
  Flex,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/layout";
import { doc, getDocFromServer } from "firebase/firestore";
import { db } from "../../services/firebase";
import { useParams } from "react-router";
import * as Scroll from "react-scroll";
import { Skeleton } from "@chakra-ui/skeleton";
import { useBreakpointValue } from "@chakra-ui/media-query";
import { Image } from "@chakra-ui/image";
import { Button } from "@chakra-ui/button";
import ContactModal from "../Modals/ContactModal";

const Details = () => {
  const [product, setProduct] = useState({});
  const { singleProduct } = useParams();
  const [showModal, setShowModal] = useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false });
  useEffect(() => {
    const docRef = doc(db, "products", singleProduct);
    getDocFromServer(docRef)
      .then((result) => {
        if (result.exists()) {
          setProduct(result.data());
        }
      })
      .catch((err) => {
        console.error(err);
      });
    let scroll = Scroll.animateScroll;
    scroll.scrollToTop();
  }, [singleProduct]);
  return (
    <>
      {Object.keys(product)[0] ? (
        <Box h="100%" w="100vw">
          <Text mt="3" align="center" fontSize="3xl" fontWeight="medium">
            {product.name}
          </Text>
          <Flex justify="center" mt="2">
            <hr
              style={{
                width: "350px",
                height: "1px",
                backgroundColor: "black",
              }}
            />
          </Flex>
          <Stack
            direction={isMobile ? "column" : "row"}
            mt="7"
            justify="center"
            align={isMobile && "center"}
            w="100vw"
          >
            <Image
              src={product.imageURL}
              w={isMobile ? "100vw" : "50vw"}
              objectFit={"cover"}
              alt={product.name}
            />
            <Flex flexDir="column" w="full" pl={isMobile ? "3" : "10"}>
              <Text fontWeight={"600"} fontSize={"xl"} mb={2} mt="5">
                {product.tagLine}
              </Text>
              <Text fontWeight={"500"} fontSize={"lg"} ml="2" mt="3">
                Specifications
              </Text>
              <UnorderedList ml="10">
                {product.specifications.map((specification) => (
                  <ListItem key={specification}>{specification}</ListItem>
                ))}
              </UnorderedList>
              <Text fontWeight={"500"} fontSize={"lg"} ml="2" mt="3">
                Quality Checks
              </Text>
              <UnorderedList ml="10">
                {product.qualityChecks.map((check) => (
                  <ListItem key={check}>{check}</ListItem>
                ))}
              </UnorderedList>
              <Flex w="full" mt="3" justify="space-evenly">
                <Button
                  bgColor={"gray.600"}
                  _hover={{ bgColor: "gray.700" }}
                  _active={{ bgColor: "gray.800" }}
                  color="white"
                  onClick={() => setShowModal(true)}
                >
                  Inquire Now
                </Button>
                <Button
                  bgColor={"gray.600"}
                  _hover={{ bgColor: "gray.700" }}
                  _active={{ bgColor: "gray.800" }}
                  color="white"
                >
                  Download Brochure
                </Button>
              </Flex>
            </Flex>
          </Stack>
          <Text align="center" mt="10" fontWeight="normal" fontSize="3xl">
            Related Products
          </Text>
          <Flex justify="center" mt="2">
            <hr
              style={{
                width: "350px",
                height: "1px",
                backgroundColor: "black",
              }}
            />
          </Flex>
          <Flex
            direction={isMobile ? "column" : "row"}
            align="center"
            justify="center"
            mt="5"
            wrap="wrap"
            w="full"
          >
            {product.related.map((imageURL, i) => {
              return (
                <Image
                  key={i}
                  src={imageURL}
                  alt="Related Kitchen"
                  w="350px"
                  h="250px"
                  objectFit="cover"
                  mx={!isMobile && "5"}
                  my={isMobile && "3"}
                />
              );
            })}
          </Flex>
          {showModal && (
            <ContactModal
              isOpen={showModal}
              onClose={() => setShowModal(false)}
            />
          )}
        </Box>
      ) : (
        <>
          <Flex
            h="60vh"
            w="100%"
            align="center"
            justifyContent="center"
            mt="120px"
          >
            <Skeleton h="full">
              <Box w="90vw"></Box>
            </Skeleton>
          </Flex>
        </>
      )}
    </>
  );
};

export default Details;
