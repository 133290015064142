import { Flex, Button } from "@chakra-ui/react";
import { googleSignInPopup } from "../services/signInProviders";
import { ImFacebook2 } from "react-icons/im";
import { FcGoogle } from "react-icons/fc";
import { useToast } from "@chakra-ui/toast";

const SignInProviderButtons = () => {
  const toast = useToast();
  return (
    <Flex align="center" w="full" justify="center" mt="3">
      <Button
        onClick={() =>
          toast({
            title: "Sorry, That method is not available!",
            description: "Please, try other ones!",
            status: "info",
            position: "top",
            isClosable: true,
          })
        }
        mx="2"
        border={"1px solid"}
        variant="ghost"
        colorScheme="blue"
        aria-label="Sign In With Facebook"
        size="md"
        leftIcon={<ImFacebook2 />}
      >
        Facebook
      </Button>
      <Button
        onClick={googleSignInPopup}
        mx="2"
        variant="ghost"
        border={"1px solid"}
        colorScheme="red"
        aria-label="Sign In With Google"
        size="md"
        leftIcon={<FcGoogle />}
      >
        Google
      </Button>
    </Flex>
  );
};

export default SignInProviderButtons;
